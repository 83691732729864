.mentors {
  width: 98vw;
  height: 180px;
}
.text-slider {
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  height: 100%;
  margin-bottom: -6vw;
}
h5 {
  font-size: 100%;
  font-weight: normal;
}
.text-slider span {
  display: inline-flex;
  padding-left: 100%;
  animation: marquee 90s linear infinite;
  -webkit-text-stroke: 1px #bebebe;
  font-size: 160px;
  -webkit-text-fill-color: #121212;
  font-family: raleway;
  will-change: transform;
  height: 100%;
  justify-content: center;
  align-items: center;
}
span {
  font-style: inherit;
  font-weight: inherit;
}
.text-slider span:hover {
  -webkit-text-stroke: 1px #a922ff;
  -webkit-text-fill-color: #a922ff;
  font-style: italic;
  text-decoration: line-through;
}
@keyframes marquee {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}
.contentSection {
  width: 100%;
  height: auto;
}
.contentSection-h2 {
  background: #89181b;
  background-size: 200%;
  -webkit-background-clip: text;
  -webkit-text-stroke: 2px transparent;
  color: transparent;
  text-transform: uppercase;
  margin-top: -10px;
  padding-top: 10px;
  padding-left: 0;
  padding-right: 5px;
  font-size: 100px;
  font-weight: 700;
  line-height: 90%;
  transition: background-position 0.5s ease;
  position: relative;
  top: 16px;
  z-index: 1;
  margin: 110px 0px 0px 0px;
  text-align: center;
}
.contentSection-h2:hover {
  background-image: linear-gradient(to right, #4b344d 0%, #89181b 100%);
  background-color: transparent;
}
.sponsor-box {
  width: auto;
  height: auto;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 0px 4%;
  gap: 5vw;
  margin-top: 3vw;
}
.sponsor-text-h1 {
  font-size: 8.9vmax;
  font-family: "poiret one";
  color: black;
}
.spoonsor-text-box {
  display: flex;
  flex-direction: column;
  gap: 17px;
  justify-content: center;
  align-items: flex-start;
}
.sponsor-text-box small {
  font-size: 18.5px;
}
.sponsor-text-box small a {
  color: rgb(83, 83, 83);
  display: flex;
  align-items: center;
  gap: 10px;
}
.sponsor-text-box p {
  text-align: justify;
  color: black;
  font-size: 20px;
}
.mentors1 {
  width: 98vw;
  height: 180px;
  display: none;
}
.sponsor-img {
  display: flex;
  justify-content: center;
  margin: 50px 0px;
}

.sponsor-img img {
  width: 24vw;
}
.contentSection-img {
  width: 60%;
}
@media screen and (max-width: 340px) {
  .contentSection {
    width: 87%;
  }
}
@media screen and (max-width: 600px) {
  .sponsor-text-box p {
    font-size: 18px;
  }
}
@media screen and (max-width: 991px) {
  .contentSection h2 {
    font-size: 15vw;
  }
  .sponsor-img img {
    width: 46vw;
  }
}
@media screen and (max-width: 951px) {
  .sponsor-box {
    width: auto;
    height: auto;
    display: block;
    justify-content: space-evenly;
    align-items: center;
    margin: 0px 5%;
    gap: 5vw;
    margin-top: 10vw;
  }
  .mentors1 {
    display: block;
  }
  .mentors {
    display: none;
  }
  .text-slider span {
    font-size: 18vw;
  }
  .sponsor-text-h1 {
    color: black;
    text-transform: uppercase;
    font-size: 11vw;
  }
}
