.pay {
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 80px 0px;
}
.pay h2 {
  background: #89181b;
  -webkit-text-stroke: 2px transparent;
  color: transparent;
  background-clip: text;
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 36px;
  font-weight: 700;
  line-height: 110%;
}
.pay h2:hover {
  background-image: linear-gradient(to right, #4b344d 0%, #89181b 100%);
  background-color: transparent;
}
.payment {
  /* Remove the background image here */
  margin-left: auto;
  margin-right: auto;
  margin-top: 80px;
  margin-bottom: 80px;
  height: 650px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  border-radius: 50px;
  width: min(950px, 100%);
  box-shadow: 0 0.5px 0 1px rgba(255, 255, 255, 0.23) inset,
    0 1px 0 0 rgba(255, 255, 255, 0.66) inset, 0 4px 16px rgba(0, 0, 0, 0.12);
  color: white;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: background-image 0.5s ease-in-out;
  cursor: pointer;
}

.creditHeader {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.creditNumber {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 10px 40px 10px 40px;
  flex-direction: column;
  gap: 5px;
}

.creditTools {
  border-radius: 0px 50px 50px 0px;
  height: 100%;
  /* background: -webkit-linear-gradient(
    -86deg,
    #f05a28 5%,
    #d91c5c 53%,
    #70ffcb 91%
  ); */
  /* background-image: url("../../../assets/callPro.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat; */
  padding: 10px 40px 0 40px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 50px;
}

.toolsTitle h3 {
  font-size: 26px;
  font-weight: 500;
  color: white;
}

.toolsTitle p {
  font-size: 11px;
  font-weight: 500;
  color: white;
}

.toolsTitle h2 {
  font-size: 26px;
  font-weight: 500;
  color: white;
}

.creditNumber p {
  font-size: 11px;
  font-weight: 500;
  color: white;
}

.creditHeader h3 {
  font-size: 30px;
  font-weight: 500;
  color: white;
}
.input-field {
  background: rgba(255, 255, 255, 0.1);
  margin-bottom: 10px;
  margin-top: 3px;
  line-height: 1.5em;
  font-size: 20px;
  border: none;
  border-radius: 10px;
  padding: 5px 10px 5px 10px;
  color: #fff;
  box-sizing: border-box;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.half-input-table {
  border-collapse: collapse;
  width: 100%;
}
.half-input-table td:first-of-type {
  border-right: 10px;
  width: 50%;
}
.pay-btn {
  border: none;
  background: #22b877;
  line-height: 2em;
  border-radius: 10px;
  font-size: 19px;
  font-size: 1.2rem;
  color: #fff;
  cursor: pointer;
  position: absolute;
  bottom: 25px;
  width: calc(100% - 50px);
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.pay-btn:hover {
  background: #22a877;
  color: #eee;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.input-field1 {
  background: rgba(255, 255, 255, 0.1);
  margin-bottom: 10px;
  margin-top: 3px;
  line-height: 1.5em;
  font-size: 20px;
  font-size: 1.3rem;
  border: none;
  padding: 5px 10px 5px 10px;
  color: #fff;
  box-sizing: border-box;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.input-field2 {
  display: none;
}
.creditHeader h1 {
  font-size: 28px;
}

@media screen and (max-width: 1050px) {
  .creditTools {
    gap: 0px;
  }
  .payment {
    height: 644px;
    gap: 0px;
    width: min(685px, 100%);
    background-size: cover;
    height: 450px;
  }
  .creditHeader {
    width: 35%;
  }
}

@media screen and (max-width: 780px) {
  .payment {
    flex-direction: column;
    width: 80%;
    height: 300px;
    background-size: cover;
  }
  .creditTools {
    border-radius: 0px 0px 50px 50px;
  }
  .creditHeader {
    width: 100%;
  }
  .creditHeader h1 {
    font-size: 24px;
  }
}
@media screen and (max-width: 550px) {
  .half-input-table td:first-of-type {
    display: none;
  }
  .input-field2 {
    display: inherit;
  }
  .payment {
    height: 25%;
    width: 100%;
    background-size: contain;
  }
  .pay {
    height: 925px;
    margin-top: -300px;
    margin-bottom: -250px;
  }
}
@media screen and (min-width: 1440px) {
  .pay h2 {
    font-size: 44px;
  }
}

.fade-in {
  animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
